import BigNumber from 'bignumber.js';
import { Interaction } from 'erdjs10';
import { getFarmStakingContract, getGenericData } from './generic';
import { SmartContractProfiler } from './smart-contract-profiler';

export const getFarmStats = async (contractAddress: string): Promise<any> => {
  const contract = await getFarmStakingContract(contractAddress);

  const state = await getState(contract);
  const rewardPerShare = await getRewardPerShare(contract);
  const accumulatedRewards = await getAccumulatedRewards(contract);
  const annualPercentageRewards = await getAnnualPercentageRewards(contract);
  const rewardsLeft = await getRewardsLeft(contract);
  const perBlockRewardAmount = await getPerBlockRewardAmount(contract);

  return {
    contractAddress,
    state,
    rewardPerShare,
    accumulatedRewards,
    annualPercentageRewards,
    rewardsLeft,
    perBlockRewardAmount
  };
};

export const getState = async (
  contract: SmartContractProfiler
): Promise<string> => {
  const interaction: Interaction = contract.methods.getState([]);
  const response = await getGenericData(contract, interaction);
  return response.firstValue?.valueOf().name;
};

export const getRewardPerShare = async (
  contract: SmartContractProfiler
): Promise<number | undefined> => {
  const interaction: Interaction = contract.methods.getRewardPerShare([]);
  const response = await getGenericData(contract, interaction);

  const value = new BigNumber(response.firstValue?.valueOf() ?? 0).toNumber();
  return value;
};

export const getAccumulatedRewards = async (
  contract: SmartContractProfiler
): Promise<number | undefined> => {
  const interaction: Interaction = contract.methods.getAccumulatedRewards([]);
  const response = await getGenericData(contract, interaction);

  const value = new BigNumber(response.firstValue?.valueOf() ?? 0).toNumber();
  return value;
};

export const getAnnualPercentageRewards = async (
  contract: SmartContractProfiler
): Promise<number | undefined> => {
  const interaction: Interaction = contract.methods.getAnnualPercentageRewards(
    []
  );
  const response = await getGenericData(contract, interaction);

  const value = new BigNumber(response.firstValue?.valueOf() ?? 0).toNumber();
  return value;
};

export const getRewardsLeft = async (
  contract: SmartContractProfiler
): Promise<number | undefined> => {
  const interaction: Interaction = contract.methods.getRewardsLeft([]);
  const response = await getGenericData(contract, interaction);

  const value = new BigNumber(response.firstValue?.valueOf() ?? 0)
    .shiftedBy(-6)
    .toNumber();
  return value;
};

export const getPerBlockRewardAmount = async (
  contract: SmartContractProfiler
): Promise<number | undefined> => {
  const interaction: Interaction = contract.methods.getPerBlockRewardAmount([]);
  const response = await getGenericData(contract, interaction);

  const value = new BigNumber(response.firstValue?.valueOf() ?? 0)
    .shiftedBy(-6)
    .toNumber();
  return value;
};
