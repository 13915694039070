import * as React from 'react';
import * as apiRequests from 'apiRequests';
import { SectionProps } from 'helpers/entities';

const PauseSection = ({
  contractAddress,
  stats,
  handleContractCall
}: SectionProps) => {
  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Pause</h3>
        <p>isPaused: {stats?.isPaused ? 'true' : 'false'}</p>
        <p>
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.pause, [contractAddress])
            }
          >
            Pause
          </button>
        </p>
        <p>
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.unpause, [contractAddress])
            }
          >
            Unpause
          </button>
        </p>
      </div>
    </div>
  );
};

export default PauseSection;
