import { ContractQueryResponse } from '@elrondnetwork/erdjs-network-providers/out';
import { INetworkProvider } from '@elrondnetwork/erdjs-network-providers/out/interface';
import {
  ITransactionValue,
  IContractFunction,
  IAddress,
  SmartContract,
  SmartContractAbi,
  TypedValue
} from 'erdjs10';

export class SmartContractProfiler extends SmartContract {
  constructor(scData: { address: IAddress; abi: SmartContractAbi }) {
    super(scData);
  }

  async runQuery(
    provider: INetworkProvider,
    {
      func,
      args,
      value,
      caller
    }: {
      func: IContractFunction;
      args?: TypedValue[];
      value?: ITransactionValue;
      caller?: IAddress;
      address: IAddress;
    }
  ): Promise<ContractQueryResponse> {
    const query = super.createQuery({
      func,
      args,
      value,
      caller
    });

    const queryResponse = await provider.queryContract(query);

    return queryResponse;
  }
}
