import * as React from 'react';
import * as apiRequests from 'apiRequests';
import { SectionProps } from 'helpers/entities';

const TokensSection = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Tokens</h3>
        <p>
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.getEgld, [contractAddress])
            }
          >
            Get Egld
          </button>
        </p>
        <p>
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.getToken, [contractAddress])
            }
          >
            Get Token
          </button>
        </p>
      </div>
    </div>
  );
};

export default TokensSection;
