import * as React from 'react';
import { useState } from 'react';
import * as stakingRouter from 'apiRequests/staking.router';
import TextField from 'components/TextField';
import { SectionProps } from 'helpers/entities';

const LockedAssetTokenId = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [lockedAssetTokenId, setLockedAssetTokenId] = useState('');
  const [stakingScAddress, setStakingScAddress] = useState('');

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Set locked asset token id</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <ul>
              <TextField
                type='text'
                label='Locked asset token id'
                placeholder='Locked asset token id'
                callback={(newValue) => setLockedAssetTokenId(newValue)}
              />

              <TextField
                type='text'
                label='Staking smart contract address'
                placeholder='Staking smart contract address'
                callback={(newValue) => setStakingScAddress(newValue)}
              />
            </ul>

            <div className='mt-3'>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(stakingRouter.setLockedAssetTokenId, [
                    contractAddress,
                    lockedAssetTokenId,
                    stakingScAddress
                  ])
                }
              >
                Set
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockedAssetTokenId;
