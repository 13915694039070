import * as React from 'react';
import { useState } from 'react';
import * as apiRequests from 'apiRequests';
import { formatNumber } from 'helpers';
import { SectionProps } from 'helpers/entities';

const LimitsSection = ({
  contractAddress,
  stats,
  handleContractCall
}: SectionProps) => {
  const [buyMinLimitValue, setBuyMinLimitValue] = useState('');
  const [buyMaxLimitValue, setBuyMaxLimitValue] = useState('');

  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Limits</h3>
        <p>Buy Min Limit: {formatNumber(stats?.buyMinLimit)}</p>
        <p>
          Set Buy Min Limit:
          <input
            type='number'
            onChange={(event) => setBuyMinLimitValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.setBuyMinLimit, [
                buyMinLimitValue,
                contractAddress
              ])
            }
          >
            Save
          </button>
        </p>
        <p>Buy Max Limit: {formatNumber(stats?.buyMaxLimit)}</p>
        <p>
          Set Buy Max Limit:
          <input
            type='number'
            onChange={(event) => setBuyMaxLimitValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.setBuyMaxLimit, [
                buyMaxLimitValue,
                contractAddress
              ])
            }
          >
            Save
          </button>
        </p>
      </div>
    </div>
  );
};

export default LimitsSection;
