import * as React from 'react';
import { useEffect, useState } from 'react';
import { transactionServices } from '@elrondnetwork/dapp-core';
import { TransactionResponse } from 'apiRequests';
import * as vestingRequests from 'apiRequests/vesting';
import { useLocalStorage } from 'helpers';
import SettingsSection from './SettingsSection';
import StateSection from './StateSection';

const VestingContractSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState<any>({});
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'sessionId',
    null
  );
  const [contractAddress, setContractAddress] = useLocalStorage<string>(
    'vestingContractAddress',
    ''
  );

  const init = async () => {
    try {
      setIsLoading(true);

      const _stats = await vestingRequests.getVestingStats(contractAddress);
      setStats(_stats);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [contractAddress]);

  const handleRefreshData = () => {
    setSessionId(null);
    init();
  };

  const handleContractCall = async (
    fetcher: (...payload: any) => Promise<TransactionResponse>,
    payload?: any
  ) => {
    const response = await fetcher(...payload);
    setSessionId(response.sessionId);
  };

  transactionServices.useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: handleRefreshData,
    onFail: handleRefreshData,
    onCancelled: handleRefreshData
  });

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h2>Vesting Contract settings</h2>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div className='card mb-2'>
                <div className='card-body'>
                  <div className='form-group'>
                    <label>Contract address: {contractAddress}</label>
                    <input
                      type='text'
                      className='form-control'
                      value={contractAddress}
                      onChange={(event) =>
                        setContractAddress(event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <StateSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
              <SettingsSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VestingContractSettings;
