export const chainID = '1';
export const environment = 'mainnet';

export const settingsContractAddress =
  'erd1qqqqqqqqqqqqqpgqm4f0ls8h6xuqjjf03ryw5pvw9lc7fwphd8ssusyvzr';
// 'erd1qqqqqqqqqqqqqpgq985uezdax9yvum9rcceeuy92w3jaw7fcasss8km47v';

// export const contractAddress =
//   'erd1qqqqqqqqqqqqqpgqeq3nas0pkwvz2zj7ee0ftl6guum6fcpgzjzq48z577';

export const esdtSystemContractAddress =
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u';

export const dAppName = 'Mainnet Admin uPark';

export const apiAddress = 'https://api.elrond.com';
export const gatewayAddress = 'https://gateway.elrond.com';

export const tokenOwner =
  'erd1c6dy37wlxslxqlzxgz66dqmzdwvf2my8444c0u8e8s7mpjt4zjzqf28axl';

export const contractOwner =
  'erd1c6dy37wlxslxqlzxgz66dqmzdwvf2my8444c0u8e8s7mpjt4zjzqf28axl';

// erd1c6dy37wlxslxqlzxgz66dqmzdwvf2my8444c0u8e8s7mpjt4zjzqf28axl

export const UPARK = {
  identifier: 'UPARK-982dd6',
  decimals: 6
};
