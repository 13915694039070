import { refreshAccount, sendTransactions } from '@elrondnetwork/dapp-core';
import { ProxyNetworkProvider } from '@elrondnetwork/erdjs-network-providers/out';
import axios from 'axios';
import { TypedOutcomeBundle } from 'erdjs10';
import {
  AbiRegistry,
  Address,
  ContractFunction,
  Interaction,
  SmartContract,
  SmartContractAbi,
  TokenPayment,
  TypedValue,
  ResultsParser
} from 'erdjs10';
import { TransactionResponse } from 'apiRequests';
import { apiAddress, chainID } from 'config';
import { SmartContractProfiler } from './smart-contract-profiler';

const proxy = new ProxyNetworkProvider(apiAddress, {
  timeout: 60000
});
const resultParser = new ResultsParser();

export const getGenericData = async (
  contract: SmartContractProfiler,
  interaction: Interaction
): Promise<TypedOutcomeBundle> => {
  try {
    const queryResponse = await contract.runQuery(
      proxy,
      interaction.buildQuery()
    );
    return resultParser.parseQueryResponse(
      queryResponse,
      interaction.getEndpoint()
    );
  } catch (error: any) {
    console.log('err', error);
    throw error;
  }
};

const getSmartContract = async (
  contractInterface: string,
  path: string,
  contractAddress: string
): Promise<SmartContractProfiler> => {
  const { data: rawAbi } = await axios.get(path);

  const abiRegistry = await AbiRegistry.create(rawAbi);
  const abi = new SmartContractAbi(abiRegistry, [contractInterface]);

  return new SmartContractProfiler({
    address: new Address(contractAddress),
    abi: abi
  });
};

export const getFactoryContract = async (
  contractAddress: string
): Promise<SmartContractProfiler> => {
  const contract = await getSmartContract(
    'LockedAssetFactory',
    '/factory.abi.json',
    contractAddress
  );
  return contract;
};

export const getSwapContract = async (
  contractAddress: string
): Promise<SmartContractProfiler> => {
  const contract = await getSmartContract(
    'Swap',
    '/swap.abi.json',
    contractAddress
  );
  return contract;
};

export const getFarmStakingContract = async (
  contractAddress: string
): Promise<SmartContractProfiler> => {
  const contract = await getSmartContract(
    'Farm',
    '/farm-staking.abi.json',
    contractAddress
  );
  return contract;
};

export const getStakingRouterContract = async (
  contractAddress: string
): Promise<SmartContractProfiler> => {
  const contract = await getSmartContract(
    'StakingRouter',
    'staking-router.abi.json',
    contractAddress
  );
  return contract;
};

export const isPaused = async (
  contract: SmartContractProfiler
): Promise<boolean> => {
  const interaction: Interaction = contract.methods.isPaused([]);

  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf() ?? false;
  return value;
};

export const performTransaction = async (
  func: string,
  contract: string,
  args: TypedValue[] = [],
  gasLimit = 5000000,
  value = 0
): Promise<TransactionResponse> => {
  try {
    const address = new Address(contract);
    const smartContract = new SmartContract({ address });
    const transaction = smartContract.call({
      func: new ContractFunction(func),
      value: TokenPayment.egldFromAmount(value),
      gasLimit: gasLimit,
      args,
      chainID: chainID
    });

    await refreshAccount();

    const { sessionId, error } = await sendTransactions({
      transactions: transaction.toPlainObject(),
      transactionsDisplayInfo: {
        processingMessage: `Processing ${func} transaction`,
        errorMessage: `An error has occurred during ${func}`,
        successMessage: `${func} transaction successful`
      },
      redirectAfterSign: false,
      minGasLimit: `${gasLimit}`
    });

    return { success: error !== undefined, error: error ?? '', sessionId };
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message, sessionId: null };
  }
};
