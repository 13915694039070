import * as React from 'react';
import { useState } from 'react';
import * as apiRequests from 'apiRequests';
import { SectionProps } from 'helpers/entities';

const VestingScAddressSection = ({
  contractAddress,
  stats,
  handleContractCall
}: SectionProps) => {
  const [addressValue, setAddressValue] = useState('');
  const [amountValue, setAmountValue] = useState('');
  const [amountValueResponse, setAmountValueResponse] = useState('');

  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Vesting Contract Address</h3>
        <p>Vesting Contract Address: {stats?.vestingScAddress}</p>
        <p>
          Set vesting contract address:
          <input
            type='text'
            value={addressValue}
            onChange={(event) => setAddressValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.setVestingScAddress, [
                addressValue,
                contractAddress
              ])
            }
          >
            Save
          </button>
        </p>
        {/* <p>
          EGLD amount:&nbsp;
          <input
            type='number'
            onChange={(event) => setAmountValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={async () => {
              const res = await apiRequests.getAmount(
                contractAddress,
                amountValue
              );
              setAmountValueResponse(res.toString());
            }}
          >
            Get UPARK amount
          </button>
          Result: {amountValueResponse}
        </p> */}
        <p>Token decimals: {stats?.tokenDecimals}</p>
      </div>
    </div>
  );
};

export default VestingScAddressSection;
