import * as React from 'react';
import { useEffect, useState } from 'react';
import { transactionServices } from '@elrondnetwork/dapp-core';
import { TransactionResponse } from 'apiRequests';
import * as stakingRouterRequests from 'apiRequests/staking.router';
import { useLocalStorage } from 'helpers';
import LastDeployedContract from 'pages/Deploy/LastDeployedContract';
import AddRewards from './AddRewards';
import ChangeChildContractState from './ChangeChildContractState';
import DeployChildContract from './DeployChildContract';
import LockedAssetTokenId from './LockedAssetTokenId';
import RebalanceRewards from './RebalanceRewards';
import RegisterFarmToken from './RegisterFarmToken';
import RewardTokenId from './RewardTokenId';
import SetLocalRolesFarmToken from './SetLocalRolesFarmToken';
import SetLocalRolesFarmTokenTransfer from './SetLocalRolesFarmTokenTransfer';
import SetPerBlockRewardAmount from './SetPerBlockRewardAmount';
import SetStakingScAddress from './SetStakingScAddress';
import SourceContractAddress from './SourceContractAddress';
import StakingRouterViews from './StakingRouterViews';
import StartProduceRewards from './StartProduceRewards';
import TopUpRewards from './TopUpRewards';
import TransferOwnership from './TransferOwnership';

const StakingRouterSettings = () => {
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'sessionId',
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState<any>({});

  const [stakingRouterContractAddress, setStakingRouterContractAddress] =
    useLocalStorage<string>('stakingRouterContract', '');

  const init = async () => {
    if (!stakingRouterContractAddress) {
      return;
    }

    try {
      setIsLoading(true);

      const _stats = await stakingRouterRequests.getStakingRouterStats(
        stakingRouterContractAddress
      );
      setStats(_stats);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [stakingRouterContractAddress]);

  const handleRefreshData = () => {
    setSessionId(null);
  };

  const handleContractCall = async (
    fetcher: (...payload: any) => Promise<TransactionResponse>,
    payload?: any
  ) => {
    if (!stakingRouterContractAddress) {
      return;
    }

    const response = await fetcher(...payload);
    setSessionId(response.sessionId);
  };

  transactionServices.useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: handleRefreshData,
    onFail: handleRefreshData,
    onCancelled: handleRefreshData
  });

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h2>Staking router settings</h2>
          <div className='card mb-2'>
            <div className='card-body'>
              <p>
                Staking router contract address: {stakingRouterContractAddress}
              </p>
              <input
                className='form-control'
                type='text'
                value={stakingRouterContractAddress}
                onChange={(event) =>
                  setStakingRouterContractAddress(event.target.value)
                }
              />
            </div>
          </div>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              <StakingRouterViews
                contractAddress={stakingRouterContractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
            </>
          )}
          <LastDeployedContract sessionId={sessionId} />
          <SourceContractAddress
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <DeployChildContract
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <RegisterFarmToken
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <SetLocalRolesFarmToken
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <SetLocalRolesFarmTokenTransfer
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <LockedAssetTokenId
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <ChangeChildContractState
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <SetPerBlockRewardAmount
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <StartProduceRewards
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <TransferOwnership
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <TopUpRewards
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <SetStakingScAddress
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <AddRewards
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <RewardTokenId
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
          <RebalanceRewards
            contractAddress={stakingRouterContractAddress}
            stats={{}}
            handleContractCall={handleContractCall}
          />
        </div>
      </div>
    </div>
  );
};

export default StakingRouterSettings;
