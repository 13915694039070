import * as React from 'react';
import { useState } from 'react';
import * as apiRequests from 'apiRequests';
import TextField from 'components/TextField';
import { SectionProps } from 'helpers/entities';

const DeployChildContract = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [farmingTokenId, setFarmingTokenId] = useState('');
  const [rewardTokenId, setRewardTokenId] = useState('');
  const [maxApr, setMaxApr] = useState('');
  const [minUnbondEpochs, setMinUnbondEpochs] = useState('');
  const [rewardsLocked, setRewardsLocked] = useState(false);
  const [vestingContractAddress, setVestingContractAddress] = useState('');

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy Child contracts</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <p>Deploy Child Contract:</p>
            <ul>
              <TextField
                type='text'
                label='Farming token id'
                placeholder='Farming token id'
                callback={(newValue) => setFarmingTokenId(newValue)}
              />

              <TextField
                type='text'
                label='Reward token id'
                placeholder='Reward token id'
                callback={(newValue) => setRewardTokenId(newValue)}
              />

              <TextField
                type='number'
                label='Max APR'
                placeholder='Max APR'
                callback={(newValue) => setMaxApr(newValue)}
              />

              <TextField
                type='number'
                label='Min unbond epochs'
                placeholder='Min unbond epochs'
                callback={(newValue) => setMinUnbondEpochs(newValue)}
              />

              <li className='form-group'>
                <label>Are rewards locked</label>
                <div>
                  <input
                    type='checkbox'
                    checked={rewardsLocked}
                    onChange={() => setRewardsLocked(!rewardsLocked)}
                  />
                </div>
              </li>

              <TextField
                type='text'
                label='Vesting contract address'
                placeholder='Vesting contract address'
                callback={(newValue) => setVestingContractAddress(newValue)}
              />
            </ul>

            <div className='mt-3'>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(apiRequests.deployOrUpgradeChildContract, [
                    'deploy',
                    contractAddress,
                    farmingTokenId,
                    rewardTokenId,
                    maxApr,
                    minUnbondEpochs,
                    rewardsLocked,
                    vestingContractAddress
                  ])
                }
              >
                Deploy
              </button>
              <button
                className='btn btn-primary'
                onClick={() =>
                  handleContractCall(apiRequests.deployOrUpgradeChildContract, [
                    'upgrade',
                    contractAddress,
                    farmingTokenId,
                    rewardTokenId,
                    maxApr,
                    minUnbondEpochs,
                    rewardsLocked,
                    vestingContractAddress
                  ])
                }
              >
                Upgrade
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployChildContract;
