import { refreshAccount, sendTransactions } from '@elrondnetwork/dapp-core';
import BigNumber from '@elrondnetwork/dapp-core/node_modules/bignumber.js';
import axios from 'axios';
import {
  Address,
  AddressValue,
  BigUIntValue,
  BooleanValue,
  BytesValue,
  Code,
  CodeMetadata,
  ContractFunction,
  Interaction,
  List,
  SmartContract,
  TokenPayment,
  Transaction,
  TransactionPayload,
  TypedValue,
  U64Value
} from 'erdjs10';
import {
  apiAddress,
  chainID,
  esdtSystemContractAddress,
  gatewayAddress,
  UPARK
} from '../config';
import { getGenericData, getSwapContract } from './generic';

export interface TransactionResponse {
  success: boolean;
  error: string;
  sessionId: string | null;
}

const performTransaction = async (
  func: string,
  contract: string,
  args: TypedValue[] = [],
  gasLimit = 5000000
): Promise<TransactionResponse> => {
  try {
    const address = new Address(contract);
    const smartContract = new SmartContract({ address });
    const transaction = smartContract.call({
      func: new ContractFunction(func),
      gasLimit: gasLimit,
      args,
      chainID: chainID
    });

    await refreshAccount();

    const { sessionId, error } = await sendTransactions({
      transactions: transaction.toPlainObject(),
      transactionsDisplayInfo: {
        processingMessage: `Processing ${func} transaction`,
        errorMessage: `An error has occurred during ${func}`,
        successMessage: `${func} transaction successful`
      },
      redirectAfterSign: false,
      minGasLimit: '5000000'
    });

    return { success: error !== undefined, error: error ?? '', sessionId };
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message, sessionId: null };
  }
};

export const performMultipleTransactions = async (
  func: string,
  contract: string,
  chunkArgs: TypedValue[][] = [],
  gasLimit = 5000000
): Promise<TransactionResponse> => {
  try {
    const address = new Address(contract);
    const smartContract = new SmartContract({ address });

    let transactions = chunkArgs.map((chunk) => {
      const transaction = smartContract.call({
        func: new ContractFunction(func),
        gasLimit: gasLimit,
        args: chunk,
        chainID: chainID
      });
      return transaction.toPlainObject();
    });

    const account = await refreshAccount();
    if (!account) {
      throw new Error('null account');
    }

    transactions = transactions.map((transaction, index) => ({
      ...transaction,
      nonce: account.nonce + index
    }));

    const { sessionId, error } = await sendTransactions({
      transactions: transactions,
      transactionsDisplayInfo: {
        processingMessage: `Processing ${func} transaction`,
        errorMessage: `An error has occurred during ${func}`,
        successMessage: `${func} transaction successful`
      },
      redirectAfterSign: false,
      minGasLimit: '5000000'
    });

    return { success: error !== undefined, error: error ?? '', sessionId };
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message, sessionId: null };
  }
};

export const isAddressWhitelisted = async (
  contractAddress: string,
  address: string
): Promise<boolean> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction =
    contract.methodsExplicit.isAddressWhitelisted([
      BytesValue.fromHex(new Address(address).hex())
    ]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf() ?? false;
  return value;
};

export const getAddressBuys = async (
  contractAddress: string,
  address: string
): Promise<BigNumber> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction = contract.methodsExplicit.getAddressBuys([
    BytesValue.fromHex(new Address(address).hex())
  ]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf() ?? new BigNumber(0);
  return value;
};

export const getAmount = async (
  contractAddress: string,
  amount: string
): Promise<BigNumber> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction = contract.methods.getAmount([
    new U64Value(new BigNumber(amount))
  ]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf() ?? new BigNumber(0);
  return value;
};

const _price = async (contractAddress: string): Promise<BigNumber> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction = contract.methods.price([]);
  const response = await getGenericData(contract, interaction);

  // const value =
  //   (response.firstValue?.valueOf() as BigNumber) ?? new BigNumber(0);
  // const egldValue = value.shiftedBy(-18);
  // return egldValue;

  const value = response.firstValue?.valueOf() ?? new BigNumber(0);
  return value;
};

const _buyMinLimit = async (contractAddress: string): Promise<BigNumber> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction = contract.methods.buyMinLimit([]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf() ?? new BigNumber(0);
  return value;
};

const _buyMaxLimit = async (contractAddress: string): Promise<BigNumber> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction = contract.methods.buyMaxLimit([]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf() ?? new BigNumber(0);
  return value;
};

const _vestingScAddress = async (contractAddress: string): Promise<string> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction = contract.methods.vestingScAddress([]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf() ?? '';
  return value.toString();
};

const _tokenDecimals = async (contractAddress: string): Promise<string> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction = contract.methods.tokenDecimals([]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf();
  return value.toString();
};

const _isPaused = async (contractAddress: string): Promise<boolean> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction = contract.methods.isPaused([]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf() ?? false;
  return value;
};

const _isWhitelistCheckEnabled = async (
  contractAddress: string
): Promise<boolean> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction = contract.methods.isWhitelistCheckEnabled([]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf() ?? false;
  return value;
};

const _getSaleStartTimestamp = async (
  contractAddress: string
): Promise<string> => {
  const contract = await getSwapContract(contractAddress);

  const interaction: Interaction = contract.methods.saleStartTimestamp([]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf();
  return value.toString();
};

export const getStats = async (contractAddress: string): Promise<any> => {
  const isPaused = await _isPaused(contractAddress);
  const isWhitelistCheckEnabled = await _isWhitelistCheckEnabled(
    contractAddress
  );
  const price = await _price(contractAddress);
  const buyMinLimit = await _buyMinLimit(contractAddress);
  const buyMaxLimit = await _buyMaxLimit(contractAddress);
  const vestingScAddress = await _vestingScAddress(contractAddress);
  const tokenDecimals = await _tokenDecimals(contractAddress);
  const saleStartTimestamp = await _getSaleStartTimestamp(contractAddress);

  return {
    contractAddress,
    isPaused,
    isWhitelistCheckEnabled,
    price: price.toString(10),
    vestingScAddress,
    tokenDecimals,
    buyMinLimit: buyMinLimit.toString(10),
    buyMaxLimit: buyMaxLimit.toString(10),
    saleStartTimestamp
  };
};

export const pause = async (
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('pause', contractAddress, []);
};

export const unpause = async (
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('unpause', contractAddress, []);
};

export const enableWhitelistCheck = async (
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('enableWhitelistCheck', contractAddress, []);
};

export const disableWhitelistCheck = async (
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('disableWhitelistCheck', contractAddress, []);
};

export const setPrice = async (
  value: string,
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('setPrice', contractAddress, [
    new U64Value(new BigNumber(value))
  ]);
  // const price = new BigNumber(value).shiftedBy(18);
  // return await performTransaction('setPrice', contractAddress, [
  //   new U64Value(price)
  // ]);
};

export const setSaleStartTimestamp = async (
  value: string,
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('setSaleStartTimestamp', contractAddress, [
    new U64Value(new BigNumber(value))
  ]);
};

export const setVestingScAddress = async (
  value: string,
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('setVestingScAddress', contractAddress, [
    new AddressValue(new Address(value.trim()))
  ]);
};

export const setBuyMinLimit = async (
  value: string,
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('setBuyMinLimit', contractAddress, [
    new U64Value(new BigNumber(value).shiftedBy(UPARK.decimals))
  ]);
};

export const setBuyMaxLimit = async (
  value: string,
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('setBuyMaxLimit', contractAddress, [
    new U64Value(new BigNumber(value).shiftedBy(UPARK.decimals))
  ]);
};

export const whitelistAddresses = async (
  value: string,
  contractAddress: string
): Promise<TransactionResponse> => {
  const addresses = value.split('\n');

  const chunkSize = 10;
  const chunks = [];
  for (let i = 0; i < addresses.length; i += chunkSize) {
    const chunkRaw = addresses.slice(i, i + chunkSize);
    const chunk = [
      List.fromItems(
        chunkRaw.map((address) => new AddressValue(new Address(address.trim())))
      )
    ];
    chunks.push(chunk);
  }

  console.log(`Signing ${chunks.length} transactions`);

  return await performMultipleTransactions(
    'whitelistAddresses',
    contractAddress,
    chunks,
    12000000
  );
};

export const whitelistAddress = async (
  value: string,
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('whitelistAddress', contractAddress, [
    new AddressValue(new Address(value))
  ]);
};

export const blacklistAddress = async (
  value: string,
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('blacklistAddress', contractAddress, [
    new AddressValue(new Address(value))
  ]);
};

export const getEgld = async (
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('getEgld', contractAddress, []);
};

export const getToken = async (
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('getToken', contractAddress, []);
};

export const mint = async (
  identifier: string,
  address: string,
  amount: string
): Promise<TransactionResponse> => {
  return await performTransaction('ESDTLocalMint', address, [
    new BytesValue(Buffer.from(identifier)),
    new U64Value(new BigNumber(amount).shiftedBy(UPARK.decimals))
  ]);
};

export const burn = async (
  identifier: string,
  address: string,
  amount: string
): Promise<TransactionResponse> => {
  return await performTransaction('ESDTLocalBurn', address, [
    new BytesValue(Buffer.from(identifier)),
    new U64Value(new BigNumber(amount).shiftedBy(UPARK.decimals))
  ]);
};

export const setSpecialRole = async (
  identifier: string,
  address: string,
  role: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setSpecialRole',
    esdtSystemContractAddress,
    [
      new BytesValue(Buffer.from(identifier)),
      new AddressValue(new Address(address)),
      new BytesValue(Buffer.from(role))
    ],
    60000000
  );
};

export const deployOrUpgrade = async (
  operation: 'deploy' | 'upgrade',
  address: string | undefined,
  code: Code,
  args: any[],
  gasLimit = 25000000
): Promise<TransactionResponse> => {
  try {
    const codeMetadata = new CodeMetadata(
      true, // isUpgradeable,
      true, // isReadable,
      false, // isPayable,
      true // payableBySc
    );
    const smartContract = new SmartContract({
      address: address ? new Address(address) : undefined
    });
    const deployArguments = {
      code,
      gasLimit: gasLimit,
      codeMetadata,
      initArguments: args,
      value: TokenPayment.egldFromAmount(0),
      chainID: chainID
    };
    const transaction =
      operation === 'deploy'
        ? smartContract.deploy(deployArguments)
        : smartContract.upgrade(deployArguments);

    const data = transaction.getData();
    console.log(data);
    const dataContents = data.toString();

    const processedTransaction = new Transaction({
      nonce: transaction.getNonce(),
      value: transaction.getValue(),
      receiver: transaction.getReceiver(),
      gasPrice: transaction.getGasPrice(),
      gasLimit: transaction.getGasLimit(),
      chainID: chainID,
      data: new TransactionPayload(dataContents),
      options: transaction.getOptions(),
      version: transaction.getVersion()
    });

    await refreshAccount();

    console.log(processedTransaction.toPlainObject());

    const { sessionId, error } = await sendTransactions({
      transactions: processedTransaction.toPlainObject(),
      transactionsDisplayInfo: {
        processingMessage: `Processing ${operation}`,
        errorMessage: `An error has occurred during ${operation}`,
        successMessage: `${operation} successful`
      },
      redirectAfterSign: false,
      minGasLimit: '25000000'
    });

    return { success: error !== undefined, error: error ?? '', sessionId };
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message, sessionId: null };
  }
};

export const getContractAddress = async (address: string): Promise<string> => {
  try {
    const { data } = await axios.get(`${apiAddress}/transactions/${address}`);
    const rawTopic = data?.logs?.events[0]?.topics[0];
    const rawAddress = Buffer.from(rawTopic, 'base64').toString('hex');
    return Address.fromHex(rawAddress).bech32();
  } catch {
    return '-';
  }
};

export const deposit = async (
  identifier: string,
  address: string,
  amount: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'ESDTTransfer',
    address,
    [
      new BytesValue(Buffer.from(identifier)),
      new U64Value(new BigNumber(amount).shiftedBy(UPARK.decimals)),
      new BytesValue(Buffer.from('deposit'))
    ],
    5000000
  );
};

export const setAllTransferRoleAddresses = async (
  tokenId: string
): Promise<TransactionResponse> => {
  const args = [new BytesValue(Buffer.from(tokenId))];

  return await performTransaction(
    'sendAllTransferRoleAddresses',
    esdtSystemContractAddress,
    args,
    70000000
  );
};

export const checkWhitelistAddresses = async (
  contract: string,
  rawAddresses: string
) => {
  const addresses = rawAddresses.split('\n');

  const rawKeys = await axios
    .get(`${gatewayAddress}/address/${contract}/keys`)
    .then((res) => res.data.data.pairs);

  const filteredKeys = Object.keys(rawKeys)
    .filter(
      (key) =>
        key.startsWith('77686974656c697374') &&
        key !== '77686974656c6973745f636865636b'
    )
    .map((key) => key.replace('77686974656c697374', ''));

  const contractAddress = filteredKeys.map((key) => new Address(key).bech32());

  const response = addresses.map((adddress) => ({
    adddress,
    whitelisted: contractAddress.includes(adddress.trim())
  }));
  return response;
};

export const getBuyAddresses = async (contract: string) => {
  const rawKeys = await axios
    .get(`${gatewayAddress}/address/${contract}/keys`)
    .then((res) => res.data.data.pairs);

  const filteredKeys = Object.keys(rawKeys)
    .filter((key) => key.startsWith('62757973'))
    .map((key) => key.replace('62757973', ''));

  const map = filteredKeys.map((key) => {
    const address = new Address(key).bech32();
    const value = new BigNumber(rawKeys[`62757973${key}`], 16)
      .shiftedBy(-UPARK.decimals)
      .toFixed();
    return {
      key: address,
      value
    };
  });

  return map;
};

export const clearBuys = async (
  contractAddress: string,
  value: string
): Promise<TransactionResponse> => {
  const addresses = value.split('\n');

  const chunkSize = 10;
  const chunks = [];
  for (let i = 0; i < addresses.length; i += chunkSize) {
    const chunkRaw = addresses.slice(i, i + chunkSize);
    const chunk = [
      List.fromItems(
        chunkRaw.map((address) => new AddressValue(new Address(address.trim())))
      )
    ];
    chunks.push(chunk);
  }

  return await performMultipleTransactions(
    'clearBuys',
    contractAddress,
    chunks,
    12000000
  );
};

export const deployOrUpgradeChildContract = async (
  operation: 'deploy' | 'upgrade',
  contractAddress: string,
  farmingTokenId: string,
  rewardTokenId: string,
  maxApr: string,
  minUnbondEpochs: string,
  rewardsLocked: boolean,
  vestingContractAddress: string
): Promise<TransactionResponse> => {
  const method =
    operation === 'deploy' ? 'deployChildContract' : 'upgradeChildContract';
  const divisionSafetyConstant = new BigNumber(10).shiftedBy(12);
  const args = [];

  args.push(
    new BytesValue(Buffer.from(farmingTokenId)),
    new BytesValue(Buffer.from(rewardTokenId)),
    new BigUIntValue(new BigNumber(divisionSafetyConstant)),
    new BigUIntValue(new BigNumber(maxApr).shiftedBy(2)),
    new U64Value(new BigNumber(minUnbondEpochs)),
    new BooleanValue(rewardsLocked)
  );

  try {
    args.push(
      BytesValue.fromHex(Address.fromBech32(vestingContractAddress).hex())
    );
  } catch (e) {
    console.log('Vesting address is not a valid bech32 address');
  }

  return await performTransaction(method, contractAddress, args, 50000000);
};
