import * as React from 'react';
import { useState } from 'react';
import {
  transactionServices,
  useGetAccountInfo
} from '@elrondnetwork/dapp-core';
import * as apiRequests from 'apiRequests';
import { TransactionResponse } from 'apiRequests';
import { useLocalStorage } from 'helpers';

const TokenSettings = () => {
  const { address } = useGetAccountInfo();

  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'sessionId',
    null
  );

  const handleRefreshData = () => {
    setSessionId(null);
  };

  const handleContractCall = async (
    fetcher: (...payload: any) => Promise<TransactionResponse>,
    payload: any[]
  ) => {
    const response = await fetcher(...payload);
    setSessionId(response.sessionId);
  };

  transactionServices.useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: handleRefreshData,
    onFail: handleRefreshData,
    onCancelled: handleRefreshData
  });

  const [identifier, setIdentifier] = useState('');
  const [mintValue, setMintValue] = useState('');
  const [burnValue, setBurnValue] = useState('');
  const [depositValue, setDepositValue] = useState('');
  const [contractValue, setContractValue] = useState('');
  const [roleValue, setRoleValue] = useState('');
  const [roleAddressValue, setRoleAddressValue] = useState('');
  const [tokenId, setTokenId] = useState('');

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h2>Token settings</h2>

          <div className='card mb-2'>
            <div className='card-body'>
              <input
                type='text'
                placeholder='token identifier'
                value={identifier}
                onChange={(event) => setIdentifier(event.target.value)}
              />
              <p>
                <select onChange={(event) => setRoleValue(event.target.value)}>
                  <option value='-'>-</option>
                  <option value='ESDTRoleLocalBurn'>ESDTRoleLocalBurn</option>
                  <option value='ESDTRoleLocalMint'>ESDTRoleLocalMint</option>
                  <option value='ESDTTransferRole'>ESDTTransferRole</option>
                </select>
                <input
                  type='text'
                  onChange={(event) => setRoleAddressValue(event.target.value)}
                />
                <button
                  className='btn btn-primary'
                  onClick={() =>
                    handleContractCall(apiRequests.setSpecialRole, [
                      identifier,
                      roleAddressValue,
                      roleValue
                    ])
                  }
                >
                  Set Special Role
                </button>
              </p>
              <p>
                <input
                  type='number'
                  onChange={(event) => setMintValue(event.target.value)}
                />
                <button
                  className='btn btn-primary'
                  onClick={() =>
                    handleContractCall(apiRequests.mint, [
                      identifier,
                      address,
                      mintValue
                    ])
                  }
                >
                  Mint
                </button>
              </p>
              <p>
                <input
                  type='number'
                  onChange={(event) => setBurnValue(event.target.value)}
                />
                <button
                  className='btn btn-primary'
                  onClick={() =>
                    handleContractCall(apiRequests.burn, [
                      identifier,
                      address,
                      burnValue
                    ])
                  }
                >
                  Burn
                </button>
              </p>
              <p>
                <input
                  type='text'
                  placeholder='contract'
                  onChange={(event) => setContractValue(event.target.value)}
                />
                <input
                  type='number'
                  placeholder='amount'
                  onChange={(event) => setDepositValue(event.target.value)}
                />
                <button
                  className='btn btn-primary'
                  onClick={() =>
                    handleContractCall(apiRequests.deposit, [
                      identifier,
                      contractValue,
                      depositValue
                    ])
                  }
                >
                  Deposit
                </button>
              </p>
              <p>
                <input
                  type='text'
                  placeholder='Token id'
                  onChange={(event) => setTokenId(event.target.value)}
                />
                <button
                  className='btn btn-primary'
                  onClick={() =>
                    handleContractCall(
                      apiRequests.setAllTransferRoleAddresses,
                      [tokenId]
                    )
                  }
                >
                  Send all transfer role addresses
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenSettings;
