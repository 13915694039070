import * as React from 'react';

export interface TextFieldProps {
  label: string;
  placeholder: string;
  type: string;
  callback: (text: string) => void;
}

const TextField = ({ label, placeholder, type, callback }: TextFieldProps) => {
  return (
    <>
      <li className='form-group'>
        <label>{label}</label>
        <input
          className='form-control'
          type={type}
          placeholder={placeholder}
          onChange={(event) => callback(event.target.value)}
        />
      </li>
    </>
  );
};

export default TextField;
