import BigNumber from 'bignumber.js';
import {
  AbiRegistry,
  Address,
  AddressType,
  AddressValue,
  BigUIntValue,
  BytesValue,
  CompositeType,
  Field,
  Interaction,
  Struct,
  TypedValue,
  U32Value,
  U64Value,
  U8Value,
  VariadicType,
  VariadicValue
} from 'erdjs10';
import * as stakingRouterAbi from 'abis/staking-router.abi.json';
import { TransactionResponse, performMultipleTransactions } from 'apiRequests';
import { UPARK } from 'config';
import {
  getGenericData,
  getStakingRouterContract,
  performTransaction
} from './generic';
import { SmartContractProfiler } from './smart-contract-profiler';
import { UPARK_GROUP_NAME } from 'helpers';

export interface RebalanceRewardsInfo {
  address: string;
  distribution: BigNumber;
  finalRewardBlockNonce: BigNumber;
}

export const getStakingRouterStats = async (
  contractAddress: string
): Promise<any> => {
  const contract = await getStakingRouterContract(contractAddress);
  const groupIds = await getGroupIdentifiers(contract);
  console.log('GROUP IDS: ' + groupIds);

  return {
    contractAddress,
    groupIds
  };
};

export const getGroupIdentifiers = async (
  contract: SmartContractProfiler
): Promise<string[] | undefined> => {
  const interaction: Interaction = contract.methodsExplicit.getGroups([
    BytesValue.fromUTF8('UPARK')
  ]);
  const response = await getGenericData(contract, interaction);

  console.log('GROUP IDS RESPONSE: ', response);
  const value = response.firstValue?.valueOf();
  return value;
};

export const getAddressesByGroup = async (
  contractAddress: string,
  groupIdentifier: string
): Promise<Address[] | undefined> => {
  const contract = await getStakingRouterContract(contractAddress);

  const interaction: Interaction = contract.methodsExplicit.getGroups([
    new BytesValue(Buffer.from(groupIdentifier))
  ]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf();
  return value;
};

export const getRewardTokenId = async (
  contractAddress: string
): Promise<number | undefined> => {
  const contract = await getStakingRouterContract(contractAddress);

  const interaction: Interaction = contract.methodsExplicit.getRewardTokenId(
    []
  );
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf();
  return value;
};

export const getVestingAddressByGroup = async (
  contractAddress: string,
  groupIdentifier: string
): Promise<Address | undefined> => {
  const contract = await getStakingRouterContract(contractAddress);

  const interaction: Interaction =
    contract.methodsExplicit.getVestingAddressByGroupIdentifier([
      new BytesValue(Buffer.from(groupIdentifier))
    ]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf();
  return value;
};

export const setLockedAssetTokenId = async (
  contractAddress: string,
  lockedAssetTokenId: string,
  stakingAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setLockedAssetTokenId',
    contractAddress,
    [
      new BytesValue(Buffer.from(lockedAssetTokenId)),
      BytesValue.fromHex(Address.fromBech32(stakingAddress).hex())
    ],
    50000000
  );
};

export const registerFarmToken = async (
  contractAddress: string,
  stakingAddress: string,
  tokenDisplayName: string,
  tokenTicker: string,
  numDecimals: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'registerFarmToken',
    contractAddress,
    [
      new AddressValue(new Address(stakingAddress)),
      new BytesValue(Buffer.from(tokenDisplayName)),
      new BytesValue(Buffer.from(tokenTicker)),
      new U32Value(new BigNumber(numDecimals))
    ],
    150000000,
    0.05
  );
};

export const setLocalRolesFarmToken = async (
  contractAddress: string,
  stakingAddress: string,
  address: string,
  roles: number[]
): Promise<TransactionResponse> => {
  const args = [
    new AddressValue(new Address(stakingAddress)),
    new AddressValue(new Address(address)),
    ...roles.map((role) => new U8Value(new BigNumber(role)))
  ];

  return await performTransaction(
    'setLocalRolesFarmToken',
    contractAddress,
    args,
    150000000
  );
};

export const setLocalRolesFarmTokenMultiple = async (
  contractAddress: string,
  stakingAddress: string,
  addresses: string[],
  roles: number[]
): Promise<TransactionResponse> => {
  const chunks = [];
  for (const address of addresses) {
    const args = [
      new AddressValue(new Address(stakingAddress)),
      new AddressValue(new Address(address)),
      ...roles.map((role) => new U8Value(new BigNumber(role)))
    ];
    chunks.push(args);
  }

  console.log(`Signing ${chunks.length} transactions`);

  return await performMultipleTransactions(
    'setLocalRolesFarmToken',
    contractAddress,
    chunks,
    70000000
  );
};

export const changeChildContractState = async (
  contractAddress: string,
  stakingAddress: string,
  method: 'resumeChildContract' | 'pauseChildContract'
): Promise<TransactionResponse> => {
  return await performTransaction(
    method,
    contractAddress,
    [new AddressValue(new Address(stakingAddress))],
    50000000
  );
};

export const startProduceRewards = async (
  contractAddress: string,
  stakingAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'startProduceRewards',
    contractAddress,
    [new AddressValue(new Address(stakingAddress))],
    50000000
  );
};

export const setPerBlockRewardAmount = async (
  contractAddress: string,
  stakingAddress: string,
  perBlockRewardAmount: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setPerBlockRewardAmount',
    contractAddress,
    [
      new AddressValue(new Address(stakingAddress)),
      new BigUIntValue(new BigNumber(perBlockRewardAmount))
    ],
    50000000
  );
};

export const transferOwnership = async (
  contractAddress: string,
  stakingAddress: string,
  address: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'transferOwnership',
    contractAddress,
    [
      new AddressValue(new Address(stakingAddress)),
      new AddressValue(new Address(address))
    ],
    100000000
  );
};

export const topUpRewards = async (
  contractAddress: string,
  stakingAddress: string,
  identifier: string,
  amount: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'ESDTTransfer',
    contractAddress,
    [
      new BytesValue(Buffer.from(identifier)),
      new U64Value(new BigNumber(amount).shiftedBy(UPARK.decimals)),
      new BytesValue(Buffer.from('topUpRewards')),
      new AddressValue(new Address(stakingAddress))
    ],
    50000000
  );
};

export const setStakingScAddress = async (
  contractAddress: string,
  address: string,
  farmTokenId: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setStakingScAddress',
    contractAddress,
    [
      new BytesValue(Buffer.from(farmTokenId)),
      new AddressValue(new Address(address))
    ],
    20000000
  );
};

export const setSourceContractAddress = async (
  contractAddress: string,
  sourceaddress: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setSourceContractAddress',
    contractAddress,
    [new AddressValue(new Address(sourceaddress))],
    20000000
  );
};

export const addOrRemoveGroupAddress = async (
  contractAddress: string,
  groupIdentifier: string,
  addresses: string[],
  method: 'addAddressToGroup' | 'removeAddressFromGroup'
): Promise<TransactionResponse> => {
  const chunks = [];
  for (const address of addresses) {
    const args = [
      new BytesValue(Buffer.from(groupIdentifier)),
      new AddressValue(new Address(address))
    ];
    chunks.push(args);
  }

  console.log(`Signing ${chunks.length} transactions`);

  return await performMultipleTransactions(
    method,
    contractAddress,
    chunks,
    25000000
  );
};

export const addRewards = async (
  contractAddress: string,
  rewardTokenIdentifier: string,
  amount: string
): Promise<TransactionResponse> => {
  const groupIdentifier = UPARK_GROUP_NAME;
  return await performTransaction(
    'ESDTTransfer',
    contractAddress,
    [
      new BytesValue(Buffer.from(rewardTokenIdentifier)),
      new U64Value(new BigNumber(amount).shiftedBy(UPARK.decimals)),
      new BytesValue(Buffer.from('addRewards')),
      new BytesValue(Buffer.from(groupIdentifier))
    ],
    100000000
  );
};

export const setRewardTokenId = async (
  contractAddress: string,
  rewardTokenId: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setRewardTokenId',
    contractAddress,
    [new BytesValue(Buffer.from(rewardTokenId))],
    25000000
  );
};

export const rebalanceRewards = async (
  contractAddress: string,
  rewardTokenIdentifier: string,
  rebalanceRewardsInfo: RebalanceRewardsInfo[],
  amount: string
): Promise<TransactionResponse> => {
  const registry = await AbiRegistry.create(stakingRouterAbi.abi as any);
  const stakingInfoType = registry.getStruct('StakingScInfo');

  let stakingInfoArgs: TypedValue[] = [];
  const groupIdentifier = UPARK_GROUP_NAME;

  stakingInfoArgs = rebalanceRewardsInfo.map((info) => {
    return new VariadicValue(
      new VariadicType(new CompositeType(new AddressType(), stakingInfoType)),
      [
        new AddressValue(new Address(info.address)),
        new Struct(stakingInfoType, [
          new Field(new U64Value(info.distribution), 'distribution'),
          new Field(
            new U64Value(info.finalRewardBlockNonce),
            'final_reward_block_nonce'
          )
        ])
      ]
    );
  });

  return await performTransaction(
    'ESDTTransfer',
    contractAddress,
    [
      new BytesValue(Buffer.from(rewardTokenIdentifier)),
      new U64Value(new BigNumber(amount).shiftedBy(UPARK.decimals)),
      new BytesValue(Buffer.from('rebalance_rewards')),
      new BytesValue(Buffer.from(groupIdentifier)),
      ...stakingInfoArgs
    ],
    100000000
  );
};
