import * as React from 'react';
import { SectionProps } from 'helpers/entities';

const StateSection = ({ stats }: SectionProps) => {
  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Contract state</h3>
        <p>
          <b>Contract address:</b> {stats.contractAddress}
        </p>
        <p>
          <b>Asset Token:</b> {stats.assetTokenId}
        </p>
        <p>
          <b>Locked Asset Token:</b> {stats.lockedAssetTokenId}
        </p>
        <p>
          <b>Init epoch:</b> {stats.initEpoch}
        </p>
        <p>
          <b>Default unlock period:</b>{' '}
          {stats.defaultUnlockPeriod
            ?.map(
              (period: any) =>
                `${period.unlock_epoch} - ${period.unlock_percent.dividedBy(
                  1000
                )}%`
            )
            .join()}
        </p>
        <p>
          <b>Cache size:</b> {stats.cacheSize}
        </p>
        <p>
          <b>State:</b> {stats.state}
        </p>
      </div>
    </div>
  );
};

export default StateSection;
