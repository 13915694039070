import * as React from 'react';
import { useState } from 'react';
import { Address, BytesValue, Code } from 'erdjs10';
import TextField from 'components/TextField';

interface DeployForwarderContractProps {
  handleSendTransaction: (
    operation: 'deploy' | 'upgrade',
    address: string | undefined,
    code: Code,
    args: any[],
    gasLimit?: number
  ) => void;
}

const DeployForwarderContract = ({
  handleSendTransaction
}: DeployForwarderContractProps) => {
  const [code, setCode] = useState<Code>();
  const [forwarderAddress, setForwarderAddress] = useState('');
  const [vestingScAddress, setVestingScAddress] = useState('');
  const [stakingRouterScAddress, setStakingRouterScAddress] = useState('');

  const changeHandler = (e: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      const toBuffer = (ab: any) => {
        const buf = Buffer.alloc(ab.byteLength);
        const view = new Uint8Array(ab);
        for (let i = 0; i < buf.length; ++i) {
          buf[i] = view[i];
        }
        return buf;
      };

      const buffer = toBuffer(reader.result);
      const _code = Code.fromBuffer(buffer);
      setCode(_code);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleSubmission = async (method: 'deploy' | 'upgrade') => {
    if (!code) {
      return;
    }

    const scAddress = method == 'deploy' ? undefined : forwarderAddress;

    const args = [
      BytesValue.fromHex(Address.fromBech32(vestingScAddress).hex()),
      BytesValue.fromHex(Address.fromBech32(stakingRouterScAddress).hex())
    ];

    handleSendTransaction(method, scAddress, code, args, 70000000);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy Forwarder contract</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <p>Deploy arguments:</p>
            <ul>
              <TextField
                type='text'
                label='Forwarder contract address (only for upgrade)'
                placeholder='Forwarder contract address'
                callback={(newValue) => setForwarderAddress(newValue)}
              />

              <TextField
                type='text'
                label='Vesting contract address'
                placeholder='Vesting contract address'
                callback={(newValue) => setVestingScAddress(newValue)}
              />
              <TextField
                type='text'
                label='Staking router contract address'
                placeholder='Staking router contract address'
                callback={(newValue) => setStakingRouterScAddress(newValue)}
              />
            </ul>

            <input
              className='mt-2'
              type='file'
              name='file'
              onChange={changeHandler}
            />

            <div className='mt-3'>
              <button
                className='btn btn-primary mr-4'
                onClick={() => handleSubmission('deploy')}
              >
                Deploy
              </button>
              <button
                className='btn btn-primary'
                onClick={() => handleSubmission('upgrade')}
              >
                Upgrade
              </button>
            </div>
            <div>
              <pre className='mt-2 pre-scrollable'>
                <code>{code?.toString()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployForwarderContract;
