import * as React from 'react';
import { useState } from 'react';
import { Code } from 'erdjs10';
import TextField from 'components/TextField';

interface DeployStakingRouterContractProps {
  handleSendTransaction: (
    operation: 'deploy' | 'upgrade',
    address: string | undefined,
    code: Code,
    args: any[],
    gasLimit?: number
  ) => void;
}

const DeployStakingRouterContract = ({
  handleSendTransaction
}: DeployStakingRouterContractProps) => {
  const [code, setCode] = useState<Code>();
  const [address, setAddress] = useState('');

  const changeHandler = (e: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      const toBuffer = (ab: any) => {
        const buf = Buffer.alloc(ab.byteLength);
        const view = new Uint8Array(ab);
        for (let i = 0; i < buf.length; ++i) {
          buf[i] = view[i];
        }
        return buf;
      };

      const buffer = toBuffer(reader.result);
      const _code = Code.fromBuffer(buffer);
      setCode(_code);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleSubmission = async (method: 'deploy' | 'upgrade') => {
    if (!code) {
      return;
    }

    const scAddress = method == 'deploy' ? undefined : address;

    handleSendTransaction(method, scAddress, code, [], 110000000);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy Staking Router contract</h2>

        <div className='card mb-2'>
          <div className='card-body'>
            <p>Deploy arguments:</p>
            <TextField
              type='text'
              label='Staking router contract address (only for upgrade)'
              placeholder='Staking router contract address'
              callback={(newValue) => setAddress(newValue)}
            />
            <input
              className='mt-2'
              type='file'
              name='file'
              onChange={changeHandler}
            />

            <div className='mt-3'>
              <button
                className='btn btn-primary mr-4'
                onClick={() => handleSubmission('deploy')}
              >
                Deploy
              </button>
              <button
                className='btn btn-primary'
                onClick={() => handleSubmission('upgrade')}
              >
                Upgrade
              </button>
            </div>
            <div>
              <pre className='mt-2 pre-scrollable'>
                <code>{code?.toString()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployStakingRouterContract;
