import * as React from 'react';
import { useState } from 'react';
import * as vestingRequests from 'apiRequests/vesting';
import { EsdtLocalRole, Milestone } from 'apiRequests/vesting';
import { SectionProps } from 'helpers/entities';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';

const SettingsSection = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const { address: userAddress } = useGetAccountInfo();

  const [tokenName, setTokenName] = useState('');
  const [tokenTicker, setTokenTicker] = useState('');
  const [tokenDecimals, setTokenDecimals] = useState('');
  const [initEpoch, setInitEpoch] = useState('');
  const [milestones, setMilestones] = useState<Milestone[]>([
    { epoch: '0', percent: '0' }
  ]);
  const [address, setAddress] = useState('');
  const [roles, setRoles] = useState<string[]>([]);
  const [transferAddress, setTransferAddress] = useState('');
  const [whitelistAddresses, setWhitelistAddresses] = useState('');

  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <div className='mb-4'>
          <h5>Register locked asset token</h5>
          <div className='form-inline'>
            <div className='form-group mb-2'>
              <label>Display name</label>
              <input
                type='text'
                className='form-control'
                value={tokenName}
                onChange={(event) => setTokenName(event.target.value)}
              />
            </div>
            <div className='form-group mx-sm-3 mb-2'>
              <label>Ticker</label>
              <input
                type='text'
                className='form-control'
                value={tokenTicker}
                onChange={(event) => setTokenTicker(event.target.value)}
              />
            </div>
            <div className='form-group mx-sm-2 mb-2'>
              <label>Decimals</label>
              <input
                type='number'
                className='form-control'
                value={tokenDecimals}
                onChange={(event) => setTokenDecimals(event.target.value)}
              />
            </div>
            <button
              type='submit'
              className='btn btn-primary mb-2'
              onClick={() =>
                handleContractCall(vestingRequests.registerLockedAssetToken, [
                  tokenName,
                  tokenTicker,
                  parseInt(tokenDecimals),
                  contractAddress
                ])
              }
            >
              Set
            </button>
          </div>
        </div>

        <div className='mb-4'>
          <h5>Set init epoch</h5>
          <div className='form-inline'>
            <div className='form-group mx-sm-2 mb-2'>
              <label>init epoch</label>
              <input
                type='number'
                className='form-control'
                value={initEpoch}
                onChange={(event) => setInitEpoch(event.target.value)}
              />
            </div>
            <button
              type='submit'
              className='btn btn-primary mb-2'
              onClick={() =>
                handleContractCall(vestingRequests.setInitEpoch, [
                  parseInt(initEpoch),
                  contractAddress
                ])
              }
            >
              Set
            </button>
          </div>
        </div>

        <div className='mb-4'>
          <h5>Set unlock period</h5>
          {milestones.map((milestone, index) => (
            <div className='form-row mb-2' key={index}>
              <div className='col'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='unlock epoch'
                  value={milestone.epoch}
                  onChange={(event) => {
                    const newMilestones = [...milestones];
                    newMilestones[index].epoch = event.target.value;
                    setMilestones(newMilestones);
                  }}
                />
              </div>
              <div className='col'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='unlock percent'
                  value={milestone.percent}
                  onChange={(event) => {
                    const newMilestones = [...milestones];
                    newMilestones[index].percent = event.target.value;
                    setMilestones(newMilestones);
                  }}
                />
              </div>
              <div className='col-2'>
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={() => {
                    const newMilestones = [...milestones];
                    newMilestones.splice(index, 1);
                    setMilestones(newMilestones);
                  }}
                >
                  Remove milestone
                </button>
              </div>
            </div>
          ))}
          <button
            type='button'
            className='btn btn-dark mr-4'
            onClick={() => {
              setMilestones([...milestones, { epoch: '', percent: '' }]);
            }}
          >
            Add milestone
          </button>
          <button
            type='submit'
            className='btn btn-primary ml-4'
            onClick={() =>
              handleContractCall(vestingRequests.setUnlockPeriod, [
                milestones,
                contractAddress
              ])
            }
          >
            Save
          </button>
        </div>

        <div className='mb-4'>
          <h5>Set local roles</h5>
          <div className='form'>
            {/* <div className='form-group mx-sm-2 mb-2'>
              <label>address</label>
              <input
                type='text'
                className='form-control'
                value={contractAddress}
                // onChange={(event) => setAddress(event.target.value)}
                disabled
              />
            </div> */}
            {/* <div className='form-group form-check mx-sm-4 mb-2 d-flex flex-column'>
              {Object.keys(EsdtLocalRole)
                .filter((x) => !(parseInt(x) >= 0))
                .map((key) => {
                  return (
                    <div key={key}>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id={key}
                        onClick={() => {
                          if (roles.includes(key)) {
                            setRoles(roles.filter((role) => role !== key));
                          } else {
                            setRoles([...roles, key]);
                          }
                        }}
                      />
                      <label className='form-check-label' htmlFor={key}>
                        {key}
                      </label>
                    </div>
                  );
                })}
            </div> */}
            <button
              type='submit'
              className='btn btn-primary mb-2'
              onClick={() =>
                handleContractCall(
                  vestingRequests.setLocalRolesLockedAssetToken,
                  [contractAddress, roles, contractAddress]
                )
              }
            >
              Set
            </button>
          </div>
        </div>

        <div className='mb-4'>
          <h5>Transfer ownership</h5>
          <div className='form-inline'>
            {/* <div className='form-group mx-sm-2 mb-2'>
              <label>address</label>
              <input
                type='text'
                className='form-control'
                value={transferAddress}
                onChange={(event) => setTransferAddress(event.target.value)}
              />
            </div> */}
            <button
              type='submit'
              className='btn btn-primary mb-2'
              onClick={() =>
                handleContractCall(vestingRequests.transferOwnership, [
                  userAddress,
                  contractAddress
                ])
              }
            >
              Set
            </button>
          </div>
        </div>

        <div className='mb-4'>
          <h5>Pause / Resume</h5>
          <div className='form-inline'>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={() =>
                handleContractCall(vestingRequests.changeState, [
                  contractAddress,
                  'pause'
                ])
              }
            >
              Pause
            </button>

            <button
              type='submit'
              className='btn btn-primary ml-3'
              onClick={() =>
                handleContractCall(vestingRequests.changeState, [
                  contractAddress,
                  'resume'
                ])
              }
            >
              Resume
            </button>
          </div>
        </div>

        <div className='mb-4'>
          <h5>Whitelist / Remove whitelist</h5>
          <textarea
            cols={100}
            rows={10}
            onChange={(event) => setWhitelistAddresses(event.target.value)}
          />
          <div className='form-inline'>
            <button
              className='btn btn-primary'
              onClick={() =>
                handleContractCall(
                  vestingRequests.changeWhitelistStatusForAddresses,
                  [contractAddress, whitelistAddresses, 'whitelist']
                )
              }
            >
              Whitelist
            </button>

            <button
              className='btn btn-primary ml-3'
              onClick={() =>
                handleContractCall(
                  vestingRequests.changeWhitelistStatusForAddresses,
                  [contractAddress, whitelistAddresses, 'removeWhitelist']
                )
              }
            >
              Remove whitelist
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsSection;
