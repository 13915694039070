import * as React from 'react';
import { useEffect, useState } from 'react';
import { transactionServices } from '@elrondnetwork/dapp-core';
import * as apiRequests from 'apiRequests';
import { TransactionResponse } from 'apiRequests';
import { useLocalStorage } from 'helpers';
import SaleSection from 'pages/Sale';
import LimitsSection from './LimitsSection';
import PauseSection from './PauseSection';
import PriceSection from './PriceSection';
import TokensSection from './TokensSection';
import VestingScAddressSection from './VestingScAddressSection';
import WhitelistSection from './WhitelistSection';
import BuysSection from './BuysSection';

const ContractSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState<any>({});
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'sessionId',
    null
  );
  const [contractAddress, setContractAddress] = useLocalStorage<string>(
    'contractAddress',
    ''
  );

  const init = async () => {
    try {
      setIsLoading(true);

      const _stats = await apiRequests.getStats(contractAddress);
      setStats(_stats);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [contractAddress]);

  const handleRefreshData = () => {
    setSessionId(null);
    init();
  };

  const handleContractCall = async (
    fetcher: (...payload: any) => Promise<TransactionResponse>,
    payload?: any
  ) => {
    const response = await fetcher(...payload);
    setSessionId(response.sessionId);
  };

  transactionServices.useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: handleRefreshData,
    onFail: handleRefreshData,
    onCancelled: handleRefreshData
  });

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h2>Contract settings</h2>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div className='card mb-2'>
                <div className='card-body'>
                  {/* <h3>Stats</h3> */}
                  <p>Contract address: {contractAddress}</p>
                  <input
                    type='text'
                    value={contractAddress}
                    onChange={(event) => setContractAddress(event.target.value)}
                  />
                </div>
              </div>
              <VestingScAddressSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
              <PauseSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
              <TokensSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
              <PriceSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
              <LimitsSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
              <WhitelistSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
              <BuysSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
              <SaleSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={handleContractCall}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContractSettings;
