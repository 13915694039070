import * as React from 'react';
import { useState } from 'react';
import * as apiRequests from 'apiRequests';
import { SectionProps } from 'helpers/entities';

const PriceSection = ({
  contractAddress,
  stats,
  handleContractCall
}: SectionProps) => {
  const [priceValue, setPriceValue] = useState('');

  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Price</h3>
        <p>Price: {stats?.price}</p>
        <p>
          Set price:
          <input
            type='number'
            value={priceValue}
            onChange={(event) => setPriceValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.setPrice, [
                priceValue,
                contractAddress
              ])
            }
          >
            Save
          </button>
        </p>
      </div>
    </div>
  );
};

export default PriceSection;
