import * as React from 'react';
import { useEffect, useState } from 'react';
import { transactionServices } from '@elrondnetwork/dapp-core';
import { Code } from 'erdjs10';
import * as apiRequests from 'apiRequests';
import { useLocalStorage } from 'helpers';
import DeployFarmStakingContract from './DeployFarmStakingContract';
import DeployForwarderContract from './DeployForwarderContract';
import DeployStakingRouterContract from './DeployStakingRouterContract';
import DeploySwapContract from './DeploySwapContract';
import DeployVestingContract from './DeployVestingContract';
import LastDeployedContract from './LastDeployedContract';

const Deploy = () => {
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'sessionId',
    null
  );

  const handleSendTransaction = async (
    operation: 'deploy' | 'upgrade',
    address: string | undefined,
    code: Code,
    args: any[],
    gasLimit = 25000000
  ) => {
    const response = await apiRequests.deployOrUpgrade(
      operation,
      address === '' ? undefined : address,
      code,
      args,
      gasLimit
    );

    setSessionId(response.sessionId);
  };

  return (
    <div className='container'>
      <LastDeployedContract sessionId={sessionId} />
      <DeploySwapContract handleSendTransaction={handleSendTransaction} />
      <DeployVestingContract handleSendTransaction={handleSendTransaction} />
      <DeployFarmStakingContract
        handleSendTransaction={handleSendTransaction}
      />
      <DeployStakingRouterContract
        handleSendTransaction={handleSendTransaction}
      />
      <DeployForwarderContract handleSendTransaction={handleSendTransaction} />
    </div>
  );
};

export default Deploy;
