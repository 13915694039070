import * as React from 'react';
import { useState } from 'react';
import * as stakingRouter from 'apiRequests/staking.router';
import TextField from 'components/TextField';
import { SectionProps } from 'helpers/entities';

const SetLocalRolesFarmTokenTransfer = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [stakingScAddress, setStakingScAddress] = useState('');
  const [forwarder0, setForwarder0] = useState('');
  const [forwarder1, setForwarder1] = useState('');
  const [forwarder2, setForwarder2] = useState('');
  const [stakingRouterAddress, setStakingRouterAddress] = useState('');

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Set local roles farm token - transfer role</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <ul>
              <TextField
                type='text'
                label='Staking smart contract address'
                placeholder='Staking smart contract address'
                callback={(newValue) => setStakingScAddress(newValue)}
              />
              <TextField
                type='text'
                label='Forwarder 0'
                placeholder='Forwarder 0'
                callback={(newValue) => setForwarder0(newValue)}
              />
              <TextField
                type='text'
                label='Forwarder 1'
                placeholder='Forwarder 1'
                callback={(newValue) => setForwarder1(newValue)}
              />
              <TextField
                type='text'
                label='Forwarder 2'
                placeholder='Forwarder 2'
                callback={(newValue) => setForwarder2(newValue)}
              />
              <TextField
                type='text'
                label='Staking Router'
                placeholder='Staking Router'
                callback={(newValue) => setStakingRouterAddress(newValue)}
              />
            </ul>

            <div className='mt-3'>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(
                    stakingRouter.setLocalRolesFarmTokenMultiple,
                    [
                      contractAddress,
                      stakingScAddress,
                      [
                        stakingScAddress,
                        forwarder0,
                        forwarder1,
                        forwarder2,
                        stakingRouterAddress
                      ],
                      [8]
                    ]
                  )
                }
              >
                Set
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetLocalRolesFarmTokenTransfer;
