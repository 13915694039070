import { dAppName } from 'config';
import ContractSettings from 'pages/ContractSettings';
import Deploy from 'pages/Deploy';
import FarmStakingSettings from 'pages/FarmStakingSettings';
import StakingRouterSettings from 'pages/StakingRouterSettings';
import TokenSettings from 'pages/TokenSettings';
import VestingContractSettings from 'pages/VestingContractSettings';
import withPageTitle from './components/PageTitle';
import Home from './pages/Home';

export const routeNames = {
  home: '/',
  contract: '/contract',
  vestingContract: '/vesting-settings',
  token: '/token',
  deploy: '/deploy',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  stakingRouterContract: '/staking-router-settings',
  farmStakingContract: '/farm-staking'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.contract,
    title: 'Contract settings',
    component: ContractSettings,
    authenticatedRoute: true
  },
  {
    path: routeNames.vestingContract,
    title: 'Vesting Contract settings',
    component: VestingContractSettings,
    authenticatedRoute: true
  },
  {
    path: routeNames.token,
    title: 'Token settings',
    component: TokenSettings,
    authenticatedRoute: true
  },
  {
    path: routeNames.deploy,
    title: 'Deploy contract',
    component: Deploy,
    authenticatedRoute: true
  },
  {
    path: routeNames.stakingRouterContract,
    title: 'Staking router settings',
    component: StakingRouterSettings,
    authenticatedRoute: true
  },
  {
    path: routeNames.farmStakingContract,
    title: 'Farm Staking settings',
    component: FarmStakingSettings,
    authenticatedRoute: true
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • Elrond ${dAppName}`
    : `Elrond ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
