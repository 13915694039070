import * as React from 'react';
import { useEffect, useState } from 'react';
import * as farmRequests from 'apiRequests/farm';
import { useLocalStorage } from 'helpers';
import StateSection from './StateSection';

const FarmStakingSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState<any>({});

  const [contractAddress, setContractAddress] = useLocalStorage<string>(
    'farmStakingContract',
    ''
  );

  const init = async () => {
    if (contractAddress === '') {
      return;
    }

    try {
      setIsLoading(true);

      const _stats = await farmRequests.getFarmStats(contractAddress);
      setStats(_stats);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [contractAddress]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h2>Farm Staking settings</h2>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div className='card mb-2'>
                <div className='card-body'>
                  <p>Contract address: {contractAddress}</p>
                  <input
                    className='form-control'
                    type='text'
                    value={contractAddress}
                    onChange={(event) => setContractAddress(event.target.value)}
                  />
                </div>
              </div>
              <StateSection
                contractAddress={contractAddress}
                stats={stats}
                handleContractCall={() => console.log('TODO')}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FarmStakingSettings;
