import * as React from 'react';
import { useEffect, useState } from 'react';
import { transactionServices } from '@elrondnetwork/dapp-core';
import BigNumber from 'bignumber.js';
import { BytesValue, Code, U64Value } from 'erdjs10';
import * as apiRequests from 'apiRequests';
import { UPARK } from 'config';
import { useLocalStorage } from 'helpers';

interface DeploySwapContractProps {
  handleSendTransaction: (
    operation: 'deploy' | 'upgrade',
    address: string | undefined,
    code: Code,
    args: any[],
    gasLimit?: number
  ) => void;
}

const DeploySwapContract = ({
  handleSendTransaction
}: DeploySwapContractProps) => {
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'sessionId',
    null
  );

  const [code, setCode] = useState<Code>();
  const [address, setAddress] = useState('');
  const [token, setToken] = useState('');
  const [decimals, setDecimals] = useState('');
  const [price, setPrice] = useState('');
  const [buyMinLimit, setBuyMinLimit] = useState('');
  const [buyMaxLimit, setBuyMaxLimit] = useState('');
  const [startSale, setStartSale] = useState('');

  const changeHandler = (e: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      const toBuffer = (ab: any) => {
        const buf = Buffer.alloc(ab.byteLength);
        const view = new Uint8Array(ab);
        for (let i = 0; i < buf.length; ++i) {
          buf[i] = view[i];
        }
        return buf;
      };

      const buffer = toBuffer(reader.result);
      const _code = Code.fromBuffer(buffer);
      setCode(_code);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleSubmission = async (operation: 'deploy' | 'upgrade') => {
    if (!code) {
      return;
    }

    const args = [
      new BytesValue(Buffer.from(token)),
      new U64Value(new BigNumber(decimals)),
      new U64Value(new BigNumber(price)),
      new U64Value(new BigNumber(buyMinLimit).shiftedBy(UPARK.decimals)),
      new U64Value(new BigNumber(buyMaxLimit).shiftedBy(UPARK.decimals)),
      new U64Value(new BigNumber(startSale))
    ];

    handleSendTransaction(
      operation,
      address === '' ? undefined : address,
      code,
      args,
      70000000
    );
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy Swap contract</h2>

        <div className='card mb-2'>
          <div className='card-body'>
            <p>
              <input
                type='text'
                placeholder='contract address'
                onChange={(event) => setAddress(event.target.value)}
              />
              <input
                type='text'
                placeholder='token identifier'
                onChange={(event) => setToken(event.target.value)}
              />
              <input
                type='number'
                placeholder='decimals'
                onChange={(event) => setDecimals(event.target.value)}
              />
              <input
                type='number'
                placeholder='price'
                onChange={(event) => setPrice(event.target.value)}
              />
              <input
                type='number'
                placeholder='Buy Min Limit'
                onChange={(event) => setBuyMinLimit(event.target.value)}
              />
              <input
                type='number'
                placeholder='Buy Max Limit'
                onChange={(event) => setBuyMaxLimit(event.target.value)}
              />
              <input
                type='number'
                placeholder='start sale timestamp'
                onChange={(event) => setStartSale(event.target.value)}
              />

              <input type='file' name='file' onChange={changeHandler} />

              <button
                className='btn btn-primary'
                onClick={() => handleSubmission('deploy')}
              >
                Deploy
              </button>
              <button
                className='btn btn-primary'
                onClick={() => handleSubmission('upgrade')}
              >
                Upgrade
              </button>
            </p>
            <div>
              <pre className='pre-scrollable'>
                <code>{code?.toString()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeploySwapContract;
