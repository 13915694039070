import * as React from 'react';
import { useEffect, useState } from 'react';
import { Address } from 'erdjs10/out';
import * as stakingRouter from 'apiRequests/staking.router';
import { SectionProps } from 'helpers/entities';
import { UPARK_GROUP_NAME } from 'helpers';

const StakingRouterViews = ({ stats, contractAddress }: SectionProps) => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [rewardTokenId, setRewardTokenId] = useState<number>();

  const init = async () => {
    if (contractAddress === '') {
      return;
    }

    try {
      loadRouterData(UPARK_GROUP_NAME);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    init();
  }, [contractAddress]);

  const loadRouterData = async (groupId: string) => {
    const groupResponse = await stakingRouter.getAddressesByGroup(
      contractAddress,
      groupId
    );
    if (groupResponse) {
      setAddresses(groupResponse);
    }

    const rewardResponse = await stakingRouter.getRewardTokenId(
      contractAddress
    );
    if (rewardResponse) {
      setRewardTokenId(rewardResponse);
    }
  };

  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Staking router contract state</h3>
        <div>
          <p>Group identifier: {UPARK_GROUP_NAME}</p>
        </div>
        <div className='mt-1'>
          <p>
            <strong>Farm addresses:</strong>
          </p>
          {addresses.map((address, index) => (
            <div className='mt-1' key={index}>
              {address.toString()}
            </div>
          ))}
        </div>
        <div className='mt-1'>
          <p>
            <strong>Reward Token Id: </strong> {rewardTokenId?.toString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StakingRouterViews;
