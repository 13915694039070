import * as React from 'react';
import { useEffect, useState } from 'react';
import { transactionServices } from '@elrondnetwork/dapp-core';
import * as apiRequests from 'apiRequests';

export interface LastDeployedContractProps {
  sessionId: string | null;
}

const LastDeployedContract = ({ sessionId }: LastDeployedContractProps) => {
  const [contract, setContract] = useState('');

  const completedTransactions =
    transactionServices.useGetSuccessfulTransactions();

  useEffect(() => {
    const fetchContractHash = async () => {
      const _hash = completedTransactions.successfulTransactions[sessionId]
        ?.transactions[0]?.hash as string | undefined;
      if (_hash) {
        const contractAddress = await apiRequests.getContractAddress(_hash);
        if (contractAddress && contractAddress !== contract) {
          setContract(contractAddress);
        }
      }
    };
    fetchContractHash();
  }, [completedTransactions]);

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='card'>
          <div className='card-body'>
            <p>Last deployed contract address: {contract}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastDeployedContract;
