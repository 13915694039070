import BigNumber from 'bignumber.js';
import {
  AbiRegistry,
  Address,
  AddressValue,
  BytesValue,
  Field,
  Interaction,
  List,
  Struct,
  U32Value,
  U64Value,
  U8Value
} from 'erdjs10';
import * as factoryAbi from 'abis/factory.abi.json';
import { performMultipleTransactions, TransactionResponse } from 'apiRequests';
import {
  getFactoryContract,
  getGenericData,
  performTransaction
} from './generic';
import { SmartContractProfiler } from './smart-contract-profiler';

export interface Milestone {
  epoch: string;
  percent: string;
}

export enum EsdtLocalRole {
  None = 0,
  Mint = 1,
  Burn = 2,
  NftCreate = 3,
  NftAddQuantity = 4,
  NftBurn = 5,
  NftAddUri = 6,
  NftUpdateAttributes = 7,
  Transfer = 8
}

export const getInitEpoch = async (
  contract: SmartContractProfiler
): Promise<number | undefined> => {
  const interaction: Interaction = contract.methods.getInitEpoch([]);
  const response = await getGenericData(contract, interaction);

  const value = new BigNumber(response.firstValue?.valueOf() ?? 0).toNumber();
  return value;
};

export const getDefaultUnlockPeriod = async (
  contract: SmartContractProfiler
): Promise<any[]> => {
  const interaction: Interaction = contract.methods.getDefaultUnlockPeriod([]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf()?.unlock_milestones ?? [];
  return value;
};

export const getLockedAssetTokenId = async (
  contract: SmartContractProfiler
): Promise<number | undefined> => {
  const interaction: Interaction = contract.methods.getLockedAssetTokenId([]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf();
  return value;
};

export const getAssetTokenId = async (
  contract: SmartContractProfiler
): Promise<string | undefined> => {
  const interaction: Interaction = contract.methods.getAssetTokenId([]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf();
  return value;
};

export const getUnlockScheduleForSFTNonce = async (
  contract: SmartContractProfiler
): Promise<string | undefined> => {
  const interaction: Interaction =
    contract.methods.getUnlockScheduleForSFTNonce([1]);
  const response = await getGenericData(contract, interaction);

  const value = response.firstValue?.valueOf();
  return value;
};

export const getCacheSize = async (
  contract: SmartContractProfiler
): Promise<number | undefined> => {
  const interaction: Interaction = contract.methods.getCacheSize([]);
  const response = await getGenericData(contract, interaction);

  const value = new BigNumber(response.firstValue?.valueOf() ?? 0).toNumber();
  return value;
};

export const getState = async (
  contract: SmartContractProfiler
): Promise<string> => {
  const interaction: Interaction = contract.methods.getState([]);
  const response = await getGenericData(contract, interaction);
  return response.firstValue?.valueOf().name;
};

export const getVestingStats = async (
  contractAddress: string
): Promise<any> => {
  const contract = await getFactoryContract(contractAddress);

  const initEpoch = await getInitEpoch(contract);
  const defaultUnlockPeriod = await getDefaultUnlockPeriod(contract);
  const lockedAssetTokenId = await getLockedAssetTokenId(contract);
  const assetTokenId = await getAssetTokenId(contract);
  const cacheSize = await getCacheSize(contract);
  const state = await getState(contract);

  return {
    contractAddress,
    initEpoch,
    defaultUnlockPeriod,
    lockedAssetTokenId,
    assetTokenId,
    cacheSize,
    state
  };
};

export const registerLockedAssetToken = async (
  name: string,
  identifier: string,
  decimals: number,
  address: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'registerLockedAssetToken',
    address,
    [
      new BytesValue(Buffer.from(name)),
      new BytesValue(Buffer.from(identifier)),
      new U32Value(new BigNumber(decimals))
    ],
    100000000,
    0.05
  );
};

export const setInitEpoch = async (
  initEpoch: number,
  address: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setInitEpoch',
    address,
    [new U64Value(new BigNumber(initEpoch))],
    6000000
  );
};

export const setUnlockPeriod = async (
  milestones: Milestone[],
  address: string
): Promise<TransactionResponse> => {
  const registry = await AbiRegistry.create(factoryAbi.abi as any);
  const unlockMilestoneType = registry.getStruct('UnlockMilestone');

  const milestoneArgs = milestones
    .map((milestone) => ({
      epoch: parseInt(milestone.epoch),
      percent: parseInt(milestone.percent) * 1000
    }))
    .map(
      (milestone) =>
        new Struct(unlockMilestoneType, [
          new Field(new U64Value(milestone.epoch), 'unlock_epoch'),
          new Field(new U64Value(milestone.percent), 'unlock_percent')
        ])
    );

  return await performTransaction(
    'setUnlockPeriod',
    address,
    milestoneArgs,
    20000000
  );
};

export const setLocalRolesLockedAssetToken = async (
  address: string,
  roles: string[],
  contractAddress: string
): Promise<TransactionResponse> => {
  // const roleArgs = roles
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   .map((role) => EsdtLocalRole[role])
  //   .map((role) => new U8Value(new BigNumber(role)));
  const args = [
    new AddressValue(new Address(address)),
    new U8Value(new BigNumber(3)),
    new U8Value(new BigNumber(4)),
    new U8Value(new BigNumber(5))
  ];

  return await performTransaction(
    'setLocalRolesLockedAssetToken',
    contractAddress,
    args,
    70000000
  );
};

export const transferOwnership = async (
  address: string,
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'transferOwnership',
    contractAddress,
    [new AddressValue(new Address(address))],
    70000000
  );
};

export const changeState = async (
  contractAddress: string,
  method: 'pause' | 'resume'
): Promise<TransactionResponse> => {
  return await performTransaction(method, contractAddress, [], 20000000);
};

export const changeWhitelistStatusForAddresses = async (
  contractAddress: string,
  rawAddresses: string,
  method: 'whitelist' | 'removeWhitelist'
): Promise<TransactionResponse> => {
  const addresses = rawAddresses.split('\n');

  const chunkSize = 1;
  const chunks = [];
  for (let i = 0; i < addresses.length; i += chunkSize) {
    const chunkRaw = addresses.slice(i, i + chunkSize);
    const chunk = [
      List.fromItems(
        chunkRaw.map((address) => new AddressValue(new Address(address.trim())))
      )
    ];
    chunks.push(chunk);
  }

  return await performMultipleTransactions(
    method,
    contractAddress,
    chunks,
    12000000
  );
};
