import * as React from 'react';
import { SectionProps } from 'helpers/entities';

const StateSection = ({ stats }: SectionProps) => {
  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Contract state</h3>
        <p>
          <b>Contract address:</b> {stats.contractAddress}
        </p>
        <p>
          <b>State:</b> {stats.state}
        </p>
        <p>
          <b>Reward Per Share:</b> {stats.rewardPerShare}
        </p>
        <p>
          <b>Accumulated Rewards:</b> {stats.accumulatedRewards}
        </p>
        <p>
          <b>Annual Percentage Rewards:</b> {stats.annualPercentageRewards}
        </p>
        <p>
          <b>Rewards left:</b> {stats.rewardsLeft}
        </p>
        <p>
          <b>Per block reward amount:</b> {stats.perBlockRewardAmount}
        </p>
      </div>
    </div>
  );
};

export default StateSection;
