import * as React from 'react';
import { useState } from 'react';
import * as apiRequests from 'apiRequests';
import { UPARK } from 'config';
import { SectionProps } from 'helpers/entities';

const BuysSection = ({ contractAddress, handleContractCall }: SectionProps) => {
  const [getAddressBuysValue, setGetAddressBuysValue] = useState('');
  const [getAddressBuysResponse, setGetAddressBuysResponse] = useState('');
  const [clearBuysValue, setClearBuysValue] = useState('');
  const [addressesBuysResponse, setAddressesBuysResponse] = useState('');

  const handleAddressesBuys = async () => {
    const response = await apiRequests.getBuyAddresses(contractAddress);
    const html = response
      .map((res) => `<li>${res.key} - ${res.value} UPARK</li>`)
      .join('');
    setAddressesBuysResponse(`<ul>${html}</ul>`);

    const clearValue = response.map((res) => res.key).join('\n');
    setClearBuysValue(clearValue);
  };

  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Buys</h3>
        <p>
          <input
            type='text'
            onChange={(event) => setGetAddressBuysValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={async () => {
              const res = await apiRequests.getAddressBuys(
                contractAddress,
                getAddressBuysValue
              );
              setGetAddressBuysResponse(
                res.shiftedBy(-UPARK.decimals).toString(10)
              );
            }}
          >
            Get Address Buys
          </button>
          Result: {getAddressBuysResponse}
        </p>
        <div>
          <p>Address Buys:</p>
          <button className='btn btn-primary' onClick={handleAddressesBuys}>
            Get buys
          </button>
          <div
            dangerouslySetInnerHTML={{
              __html: addressesBuysResponse
            }}
          ></div>
        </div>
        <div>
          <p>Clear buys:</p>
          <textarea
            cols={100}
            rows={10}
            value={clearBuysValue}
            onChange={(event) => setClearBuysValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={() => {
              handleContractCall(apiRequests.clearBuys, [
                contractAddress,
                clearBuysValue
              ]);
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default BuysSection;
