import * as React from 'react';
import { useState } from 'react';
import BigNumber from 'bignumber.js';
import {
  AbiRegistry,
  BytesValue,
  Code,
  Field,
  Struct,
  U64Value
} from 'erdjs10';
import * as factoryAbi from 'abis/factory.abi.json';
import { Milestone } from 'apiRequests/vesting';

interface DeployVestingContractProps {
  handleSendTransaction: (
    operation: 'deploy' | 'upgrade',
    address: string | undefined,
    code: Code,
    args: any[],
    gasLimit?: number
  ) => void;
}

const DeployVestingContract = ({
  handleSendTransaction
}: DeployVestingContractProps) => {
  const [code, setCode] = useState<Code>();
  const [address, setAddress] = useState('');
  const [token, setToken] = useState('');
  const [milestones, setMilestones] = useState<Milestone[]>([
    { epoch: '0', percent: '100' }
  ]);

  const changeHandler = (e: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      const toBuffer = (ab: any) => {
        const buf = Buffer.alloc(ab.byteLength);
        const view = new Uint8Array(ab);
        for (let i = 0; i < buf.length; ++i) {
          buf[i] = view[i];
        }
        return buf;
      };

      const buffer = toBuffer(reader.result);
      const _code = Code.fromBuffer(buffer);
      setCode(_code);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleSubmission = async (operation: 'deploy' | 'upgrade') => {
    if (!code) {
      return;
    }

    const registry = await AbiRegistry.create(factoryAbi.abi as any);
    const unlockMilestoneType = registry.getStruct('UnlockMilestone');

    const milestoneArgs = milestones
      .map((milestone) => ({
        epoch: new BigNumber(milestone.epoch),
        percent: new BigNumber(milestone.percent).multipliedBy(1000)
      }))
      .map(
        (milestone) =>
          new Struct(unlockMilestoneType, [
            new Field(new U64Value(milestone.epoch), 'unlock_epoch'),
            new Field(new U64Value(milestone.percent), 'unlock_percent')
          ])
      );
    const args = [new BytesValue(Buffer.from(token)), ...milestoneArgs];

    handleSendTransaction(
      operation,
      address === '' ? undefined : address,
      code,
      args,
      150000000
    );
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy Vesting contract</h2>

        <div className='card mb-2'>
          <div className='card-body'>
            <p>Deploy arguments:</p>
            <ul>
              <li className='form-group'>
                <label>
                  Current contract address{' '}
                  <span className='text-warning'>(only for upgrade)</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Current contract address'
                  onChange={(event) => setAddress(event.target.value)}
                />
              </li>
              <li className='form-group'>
                <label>Token identifier</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='token identifier'
                  onChange={(event) => setToken(event.target.value)}
                />
              </li>
              <li className='form-group'>
                <label>Unlock period</label>
                {milestones.map((milestone, index) => (
                  <div className='form-row mb-2' key={index}>
                    <div className='col'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='unlock epoch'
                        value={milestone.epoch}
                        onChange={(event) => {
                          const newMilestones = [...milestones];
                          newMilestones[index].epoch = event.target.value;
                          setMilestones(newMilestones);
                        }}
                      />
                    </div>
                    <div className='col'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='unlock percent'
                        value={milestone.percent}
                        onChange={(event) => {
                          const newMilestones = [...milestones];
                          newMilestones[index].percent = event.target.value;
                          setMilestones(newMilestones);
                        }}
                      />
                    </div>
                    <div className='col-2'>
                      <button
                        type='button'
                        className='btn btn-danger'
                        onClick={() => {
                          const newMilestones = [...milestones];
                          newMilestones.splice(index, 1);
                          setMilestones(newMilestones);
                        }}
                      >
                        Remove milestone
                      </button>
                    </div>
                  </div>
                ))}

                <button
                  type='button'
                  className='btn btn-dark'
                  onClick={() => {
                    setMilestones([...milestones, { epoch: '', percent: '' }]);
                  }}
                >
                  Add milestone
                </button>
              </li>
            </ul>

            <input type='file' name='file' onChange={changeHandler} />

            <div>
              <button
                className='btn btn-primary mr-4'
                onClick={() => handleSubmission('deploy')}
              >
                Deploy
              </button>
              <button
                className='btn btn-primary'
                onClick={() => handleSubmission('upgrade')}
              >
                Upgrade
              </button>
            </div>
            <div>
              <pre className='pre-scrollable'>
                <code>{code?.toString()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployVestingContract;
