import * as React from 'react';
import { useState } from 'react';
import BigNumber from 'bignumber.js';
import {
  BigUIntValue,
  BooleanValue,
  BytesValue,
  Code,
  U64Value
} from 'erdjs10';

interface DeployFarmStakingContractProps {
  handleSendTransaction: (
    operation: 'deploy' | 'upgrade',
    address: string | undefined,
    code: Code,
    args: any[],
    gasLimit?: number
  ) => void;
}

const DeployFarmStakingContract = ({
  handleSendTransaction
}: DeployFarmStakingContractProps) => {
  const [code, setCode] = useState<Code>();

  const changeHandler = (e: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      const toBuffer = (ab: any) => {
        const buf = Buffer.alloc(ab.byteLength);
        const view = new Uint8Array(ab);
        for (let i = 0; i < buf.length; ++i) {
          buf[i] = view[i];
        }
        return buf;
      };

      const buffer = toBuffer(reader.result);
      const _code = Code.fromBuffer(buffer);
      setCode(_code);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleSubmission = async () => {
    if (!code) {
      return;
    }

    const farmTokenId = 'LKUPARK-abcdef';
    const rewardTokenId = 'LKUPARK-abcdef';
    const divisionSafetyConstant = 1;
    const maxApr = 300;
    const minUnbondEpochs = 3;
    const areRewardsLocked = false;
    const address = '';

    const args = [
      new BytesValue(Buffer.from(farmTokenId)),
      new BytesValue(Buffer.from(rewardTokenId)),
      new BigUIntValue(new BigNumber(divisionSafetyConstant)),
      new BigUIntValue(new BigNumber(maxApr)),
      new U64Value(new BigNumber(minUnbondEpochs)),
      new BooleanValue(areRewardsLocked)
    ];

    handleSendTransaction('deploy', address, code, args, 150000000);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy Farm Staking contract</h2>

        <div className='card mb-2'>
          <div className='card-body'>
            <p>Deploy arguments:</p>
            <input type='file' name='file' onChange={changeHandler} />

            <div className='mt-3'>
              <button
                className='btn btn-primary mr-4'
                onClick={() => handleSubmission()}
              >
                Deploy
              </button>
            </div>
            <div>
              <pre className='mt-2 pre-scrollable'>
                <code>{code?.toString()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployFarmStakingContract;
