import * as React from 'react';
import { useState } from 'react';
import * as apiRequests from 'apiRequests';
import { UPARK } from 'config';
import { SectionProps } from 'helpers/entities';

const WhitelistSection = ({
  contractAddress,
  stats,
  handleContractCall
}: SectionProps) => {
  const [whitelistAddressValue, setWhitelistAddressValue] = useState('');
  const [blacklistAddressValue, setBlacklistAddressValue] = useState('');
  const [whitelistAddressesValue, setWhitelistAddressesValue] = useState('');
  const [checkWhitelistAddressesValue, setCheckWhitelistAddressesValue] =
    useState('');
  const [checkWhitelistAddressesResponse, setCheckWhitelistAddressesResponse] =
    useState('');
  const [isAddressWhitelistedValue, setIsAddressWhitelistedValue] =
    useState('');
  const [isAddressWhitelistedResponse, setIsAddressWhitelistedResponse] =
    useState('');

  const handleCheckWhitelistAddresses = async () => {
    const response = await apiRequests.checkWhitelistAddresses(
      contractAddress,
      checkWhitelistAddressesValue
    );
    const html = response
      .map(
        (res) =>
          `<li class="${res.whitelisted ? 'text-success' : 'text-danger'}">${
            res.adddress
          }</li>`
      )
      .join('');
    setCheckWhitelistAddressesResponse(`<ul>${html}</ul>`);
  };

  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Whitelist</h3>
        <p>
          isWhitelistCheckEnabled:{' '}
          {stats?.isWhitelistCheckEnabled ? 'true' : 'false'}
        </p>
        <p>
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.enableWhitelistCheck, [
                contractAddress
              ])
            }
          >
            Enable Whitelist Check
          </button>
        </p>
        <p>
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.disableWhitelistCheck, [
                contractAddress
              ])
            }
          >
            Disable Whitelist Check
          </button>
        </p>
        <p>
          Whitelist Addresses:
          <textarea
            cols={100}
            rows={10}
            onChange={(event) => setWhitelistAddressesValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.whitelistAddresses, [
                whitelistAddressesValue,
                contractAddress
              ])
            }
          >
            Save
          </button>
        </p>
        <p>
          Whitelist Address:
          <input
            type='text'
            onChange={(event) => setWhitelistAddressValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.whitelistAddress, [
                whitelistAddressValue,
                contractAddress
              ])
            }
          >
            Save
          </button>
        </p>
        <p>
          Blacklist Address:
          <input
            type='text'
            onChange={(event) => setBlacklistAddressValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={() =>
              handleContractCall(apiRequests.blacklistAddress, [
                blacklistAddressValue,
                contractAddress
              ])
            }
          >
            Save
          </button>
        </p>
        <p>
          <input
            type='text'
            onChange={(event) =>
              setIsAddressWhitelistedValue(event.target.value)
            }
          />
          <button
            className='btn btn-primary'
            onClick={async () => {
              const res = await apiRequests.isAddressWhitelisted(
                contractAddress,
                isAddressWhitelistedValue
              );
              setIsAddressWhitelistedResponse(
                res
                  ? `${isAddressWhitelistedValue} YES`
                  : `${isAddressWhitelistedValue} NO`
              );
            }}
          >
            Is Address Whitelisted
          </button>
          Result: {isAddressWhitelistedResponse}
        </p>
        <div>
          <p>Check Whitelist Addresses:</p>
          <textarea
            cols={100}
            rows={10}
            onChange={(event) =>
              setCheckWhitelistAddressesValue(event.target.value)
            }
          />
          <button
            className='btn btn-primary'
            onClick={handleCheckWhitelistAddresses}
          >
            Check
          </button>
          <div
            dangerouslySetInnerHTML={{
              __html: checkWhitelistAddressesResponse
            }}
          >
            {}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhitelistSection;
