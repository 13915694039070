import { useState } from 'react';
import BigNumber from '@elrondnetwork/dapp-core/node_modules/bignumber.js';
import { UPARK } from 'config';

export const UPARK_GROUP_NAME = 'UPARK';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      console.error(err);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (err) {
      console.error(err);
    }
  };

  return [storedValue, setValue];
};

export const formatNumber = (value: any) =>
  value ? new BigNumber(value).shiftedBy(-UPARK.decimals).toString(10) : '-';
