import * as React from 'react';
import { useState } from 'react';
import * as apiRequests from 'apiRequests';
import { SectionProps } from 'helpers/entities';

const SaleSection = ({
  contractAddress,
  stats,
  handleContractCall
}: SectionProps) => {
  const [timestampValue, setTimestampValue] = useState('');
  const [validityValue, setValidityValue] = useState(-1);

  return (
    <div className='card mb-2'>
      <div className='card-body'>
        <h3>Sale</h3>
        <p>Sale start timestamp: {stats?.saleStartTimestamp}</p>
        <p>
          Set sale start timestamp:{' '}
          <input
            type='number'
            value={timestampValue}
            onChange={(event) => setTimestampValue(event.target.value)}
          />
          <button
            className='btn btn-primary'
            onClick={() => {
              const timestampValid = !isNaN(
                new Date(parseInt(timestampValue)).getTime()
              );
              setValidityValue(timestampValid ? 1 : 0);
              if (timestampValid) {
                handleContractCall(apiRequests.setSaleStartTimestamp, [
                  timestampValue,
                  contractAddress
                ]);
              }
            }}
          >
            Save
          </button>
        </p>
        {validityValue === 0 && (
          <p className='text-danger'>Must be a valid timestamp</p>
        )}
      </div>
    </div>
  );
};

export default SaleSection;
