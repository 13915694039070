import * as React from 'react';
import { useState } from 'react';
import BigNumber from 'bignumber.js';
import * as stakingRouter from 'apiRequests/staking.router';
import { RebalanceRewardsInfo } from 'apiRequests/staking.router';
import TextField from 'components/TextField';
import { SectionProps } from 'helpers/entities';

const RebalanceRewards = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [amount, setAmount] = useState('');
  const [infoList, setInfoList] = useState<RebalanceRewardsInfo[]>([
    {
      address: '',
      distribution: new BigNumber(0),
      finalRewardBlockNonce: new BigNumber(0)
    }
  ]);
  const [rewardTokenIdentifier, setRewardTokenIdentifier] = useState('');

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Rebalance rewards</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <ul>
              <li className='form-group'>
                <label>Set staking info</label>
                {infoList.map((info, index) => (
                  <div className='form-row mb-2' key={index}>
                    <div className='col'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Address'
                        value={info.address}
                        onChange={(event) => {
                          const newInfoList = [...infoList];
                          newInfoList[index].address = event.target.value;
                          setInfoList(newInfoList);
                        }}
                      />
                    </div>
                    <div className='col'>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Distribution'
                        value={info.distribution?.toString()}
                        onChange={(event) => {
                          const newInfoList = [...infoList];
                          newInfoList[index].distribution = new BigNumber(
                            event.target.value
                          );
                          setInfoList(newInfoList);
                        }}
                      />
                    </div>
                    <div className='col'>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Last reward block nonce'
                        value={info.finalRewardBlockNonce?.toString()}
                        onChange={(event) => {
                          const newInfoList = [...infoList];
                          newInfoList[index].finalRewardBlockNonce =
                            new BigNumber(event.target.value);
                          setInfoList(newInfoList);
                        }}
                      />
                    </div>
                    <div className='col'>
                      <button
                        type='button'
                        className='btn btn-danger'
                        onClick={() => {
                          const newInfoList = [...infoList];
                          newInfoList.splice(index, 1);
                          setInfoList(newInfoList);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </li>
              <div>
                <button
                  type='button'
                  className='btn btn-dark'
                  onClick={() => {
                    setInfoList([
                      ...infoList,
                      {
                        address: '',
                        distribution: new BigNumber(0),
                        finalRewardBlockNonce: new BigNumber(0)
                      }
                    ]);
                  }}
                >
                  Add staking info
                </button>
              </div>

              <div className='mt-2'>
                <TextField
                  type='text'
                  label='Reward token id'
                  placeholder='Reward token id'
                  callback={(newValue) => setRewardTokenIdentifier(newValue)}
                />

                <TextField
                  type='text'
                  label='Amount'
                  placeholder='Amount'
                  callback={(newValue) => setAmount(newValue)}
                />
              </div>
            </ul>
            <div>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={() =>
                  handleContractCall(stakingRouter.rebalanceRewards, [
                    contractAddress,
                    rewardTokenIdentifier,
                    infoList,
                    amount
                  ])
                }
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RebalanceRewards;
